<template>
  <div class="menu pt-3 my-2">
    <b-navbar toggleable="lg">
      <div class="container p-0">
        <b-navbar-brand href="#">
          <img src="/images/logo.png" class="logo" alt="logo"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <div class="d-lg-flex align-items-center my-lg-0 my-4">
              <div class="nav-item"><painting></painting></div>
              <div class="nav-item d-flex align-items-center">
                <span class="mr-2" style="color: #000">Photography</span>
                <div>
                  <img src="/images/icons/downarrow.svg" alt="" />
                </div>
              </div>
              <div class="nav-item d-flex align-items-center">
                <span class="mr-2" style="color: #000">Sculpture</span>
                <div>
                  <img src="/images/icons/downarrow.svg" alt="" />
                </div>
              </div>
              <div class="nav-item d-flex align-items-center">
                <span class="mr-2" style="color: #000">Acessories</span>
                <div>
                  <img src="/images/icons/downarrow.svg" alt="" />
                </div>
              </div>
              <div class="nav-item">Events</div>
              <div class="nav-item">Reviews</div>
              <div class="nav-item">Advisory</div>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>
<script>
import Painting from "./dropdowns/Painting.vue";
export default {
  components: { Painting },
  data() {
    return {
      navOpen: false,
    };
  },
};
</script>
<style lang="scss">
.menu {
  color: #000;
  font-weight: 500;
}

.nav-item {
  font-size: 1rem;
  padding: 0.5rem 1rem;

  &:hover {
    background: #fafafa;
    // cursor: pointer;
  }
}

.cart-dropdown .dropdown-menu {
  min-width: 20rem;
}

@media (max-width: 640px) {
  .cart-dropdown .dropdown-menu {
    min-width: 17rem;
  }
}
</style>
