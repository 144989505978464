<template>
  <div class="menu pt-3 my-2">
    <div class="container">
      <div class="row mb-md-0 mb-3">
        <div class="col-lg-6"></div>
        <div class="col-lg-6">
          <div class="d-md-flex align-items-center">
            <search-filter></search-filter>
            <div class="d-flex align-items-center my-lg-0 my-4">
              <div style="width: 25px" class="ml-md-4 mr-2">
                <a href=""><img src="/images/icons/cart.svg" alt="" /></a>
              </div>
              <a class="px-3">Login</a>
              <a class="pl-3" style="border-left: 2px solid #71717a">Signup</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Filter from "../Filter.vue";

export default {
  components: { "search-filter": Filter },
};
</script>
<style lang="scss"></style>
