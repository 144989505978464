<template>
  <div class="d-inline nav-item" @mouseover="onOver" @mouseleave="onLeave">
    <b-dropdown
      ref="dropdown"
      size="lg"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div class="d-flex align-items-center">
          <span class="mr-2" style="color: #000">Painting</span>
          <img src="/images/icons/downarrow.svg" alt="" /></div
      ></template>
      <!-- <b-dropdown-item>cart item</b-dropdown-item> -->

      <div class="items row">
        <div class="col-lg-3 p-0 d-lg-block d-none">
          <img src="/images/articles/item1.png" alt="" class="h-100" />
        </div>
        <div class="col-lg-3 py-lg-5 py-2">
          <h5 class="headings mb-3">Style</h5>
          <a>Abstract</a>
          <a>Fine Art</a>
          <a>Modern</a>
          <a>Street Art</a>
          <a>Pop Art</a>
        </div>
        <div class="col-lg-3 py-lg-5 py-2">
          <h5 class="headings mb-3">Subject</h5>
          <a>Portrait</a>
          <a>Landscape</a>
          <a>Still Life</a>
          <a>Nature</a>
          <a>Beach</a>
        </div>
        <div class="col-lg-3 py-lg-5 py-2">
          <h5 class="headings mb-3">Medium</h5>
          <a>Oil</a>
          <a>Watercolor</a>
          <a>Acrylic</a>
          <a>Airbrush</a>
          <a>Digital</a>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  methods: {
    onOver() {
      // this.$refs.dropdown.visible = true;
    },
    onLeave() {
      // this.$refs.dropdown.visible = false;
    },
  },
};
</script>
