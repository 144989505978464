<template>
  <div>
    <Menu />
    <TopNav />
    <slot></slot>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Menu from "../components/nav/Menu.vue";
import TopNav from "../components/nav/TopNav.vue";
export default {
  components: {
    Footer,
    Menu,
    TopNav,
  },
};
</script>
